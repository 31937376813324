// 生产环境
let config = {
  baseUrl: 'https://ent.roncoo.com/gateway', // 网关地址，一般使用内网地址
  tokenName: 'ENT_WEB_TOKEN'
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    baseUrl: 'https://dev-ent.roncoos.com/gateway' // 网关地址
  }
}
export default config
